export const environment = {
	production: false,
	testing: true,

	pwa: {
		enabled: true
	},

		rest: {
		apiHost: 'https://apidev.mlbrmis.com'
	},

	authentication: {
		authority: 'https://authdev.mlbrmis.com',

		cors: [
			'apidev.mlbrmis.com',
			'authdev.mlbrmis.com'
		],

		authorize: {
			endPoint: '/connect/token',
			tokenKeyName: 'access_token',
			clientID: 'web-client',
			grantType: 'password',
			scope: 'openid profile offline_access rmis'
		},

		deauthorize: {
			endPoint: '/connect/endsession'
		},

		refresh: {
			endPoint: '/connect/endsession',
			tokenKeyName: 'refresh_token'
		}
	},

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	},

	captcha: {
		siteKey: '6Lcs76wUAAAAAG9rHkFVu_t_BAqifBDeGZB9cV30'
	},

	applicationInsightsKey: '2e6151fe-0750-432b-ad86-e9f03600becd',

	dataMigrationDate: new Date('2019-07-09')
};

export enum ApplicationType {
	Crime = 1,
	Fiduciary = 2,
	Foreign = 3,
	Headcount = 4,
	Media = 5,
	Payroll = 6,
	Pollution = 7,
	Values = 8,
	Events = 9,
	Locations = 11,
	NamedInsured = 12,
	Vehicles = 13
}

export enum ClubTypes {
	OfficeOfTheCommissioner = 2,
	JointVenture = 3,
	Club = 4
}

export enum FiduciaryPlanTypes {
	DefinedContributions = 1,
	DefinedBenefit = 2,
	ESOP = 3,
	WelfareBenefit = 4,
	Other = 5
}

export enum VehicleOwnershipType {
	Leased = 1,
	Owned = 2,
	Sponsorship = 3
}

export enum EventPromotionLevels {
	NotAtAll = 1,
	CoPromoting = 2,
	Full = 3
}

export enum VirtualEventStatuses {
	Yes = 1,
	No = 2,
	Partially = 3
}

export enum EventTypes {
	CampClinic = 1,
	StadiumTours = 2,
	Concert = 3,
	Flyover = 6,
	HelicopterTrip = 7,
	ConstructionProject = 8,
	Other = 4
}

export enum AffiliationTypes {
	ClassAFullSeason = 1,
	ClassAA = 2,
	ClassAAA = 3,
	RookieLeagueAdvanced = 5,
	RookieLeague = 6,
	ClassAShortSeason = 7,
	ClassAAdvanced = 8,
	DSL = 9,
	VSL = 10,
	MajorLeague = 13,
}

export enum DocumentTypes {
	Correspondence = 86,
	CrimeApplication = 75,
	DevelopmentPlans = 89,
	EnvironmentalQuestionnaire = 88,
	EnvironmentalReports = 90,
	EPLApplication = 77,
	FiduciaryApplication = 78,
	FiduciaryPlan5500s = 79,
	ForeignApplication = 76,
	HeadcountSchedule = 84,
	LocationSchedule = 80,
	MediaApplication = 87,
	NamedInsuredSchedule = 82,
	NonBaseballEventSchedule = 83,
	PayrollSchedule = 85,
	TankInventorySchedule = 91,
	VehicleSchedule = 81,
	PollutionApplication = 92,
}
